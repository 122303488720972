import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user.model';
import { map } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { environment } from '../../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthCognitoService extends AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService, private router: Router) {
    super();
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${environment.authURL}/login`, { "username": username, "password": password, "tenant": environment.tenant })
      .pipe(map(user => {
        user.username = username;
        var userDecoded: any = jwt_decode(user.token);
        user.tenant = userDecoded["custom:tenant"];
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  logout() {
    return this.http.post<any>(`${environment.authURL}/logout`, {}).pipe(map(user => {
      localStorage.removeItem('currentUser'); this.currentUserSubject.next(null);
      return true;
    }));
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  getUser() {
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    delete currentUser["refreshToken"];
    return currentUser;
  }

  getTokenExpirationDate(token: string): Date {
    const decoded: any = jwt_decode(token);
    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (this.currentUserValue != null) token = this.currentUserValue.token;
    if (!token) return true;
    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    var isExpired = !(date.valueOf() > new Date().valueOf());
    return isExpired;
  }

  refreshToken() {
    var self = this;
    return this.http.post<any>(`${environment.authURL}/refresh-token`, { "token": this.currentUserValue.refreshToken, "tenant": "authentication" })
      .pipe(map(data => {
        self.currentUserValue.token = data.token;
        localStorage.setItem('refreshToken', JSON.stringify(self.currentUserValue));
        return data;
      }));
  }

  isAdmin(group?: string): boolean {
    if (this.currentUserValue != null) group = this.currentUserValue.userRole;
    if (!group) return false;

    if (group == "admin") return true;
    return false;
  }

  isLoggedIn() {
    return localStorage.getItem('accessToken') && !this.isTokenExpired();
  }

  getJwtToken() {
    var currentUserToken = localStorage.getItem('accessToken');
    return currentUserToken ? currentUserToken : false;
  }

  getUsername() {
    var currentUserToken = localStorage.getItem('accessToken');
    const decoded: any = jwt_decode(currentUserToken);
    return decoded['cognito:username'];
  }

  getUserGroups() {
    var currentUserToken = localStorage.getItem('accessToken');
    const decoded: any = jwt_decode(currentUserToken);
    return decoded['cognito:groups'];
  }

  loginWithOauthToken(token: string) { }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HubioLoginService } from '@hubio/hubio-login';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public username: string;

  constructor(
    private authService: HubioLoginService,
    public router: Router
  ) { }

  ngOnInit() {
    const currentUser = this.authService.getCurrentUserTokenDecoded();
    if (currentUser) {
      this.username = currentUser.email;
    }
  }

  logout() {
    this.authService.logout();
  }

  headerVisible(product) {
    const urlSplit = this.router.url.split(';');
    return (urlSplit[0].indexOf(product) != -1);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';

import { MatIconModule } from '@angular/material/icon';
import { TokenInterceptor } from '../interceptors/token.interceptor';

import { JwtModule } from '@auth0/angular-jwt';
import { AuthenticationFactory } from './factories/authentication.factory'

import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { OktaRedirectComponent } from './components/redirects/okta/okta-redirect.component';
import { AuthSwitcherService } from './services/auth-switcher.service';

@NgModule({
  declarations: [
    LoginComponent,
    OktaRedirectComponent
  ],
  providers: [
    AuthGuard,
    {
      provide: AuthService,
      useFactory: AuthenticationFactory,
      deps: [HttpClient, JwtHelperService, Router, AuthSwitcherService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter
      }
    })
  ]
})
export class AuthModule { }

export function jwtTokenGetter() {
  return localStorage.getItem('JWT_TOKEN');
}
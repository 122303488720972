import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './widgets/header/header.component';
import { FooterComponent } from './widgets/footer/footer.component';
import { NavigationComponent } from './widgets/navigation/navigation.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from './auth/services/auth.service';
import { AuthenticationFactory } from './auth/factories/authentication.factory'

import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { AuthSwitcherService } from './auth/services/auth-switcher.service';
import { HubioCommonPagesModule } from '@hubio/hubio-common-components';
import { ErrorNotFoundComponent } from './pages/error-not-found/error-not-found.component';
import { environment } from '@environment/environment';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    ErrorNotFoundComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    HubioCommonPagesModule.forRoot(environment)
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    ErrorNotFoundComponent
  ],
  providers: [{
    provide: AuthService,
    useFactory: AuthenticationFactory,
    deps: [HttpClient, JwtHelperService, Router, AuthSwitcherService]
  }]
})
export class CoreModule { }

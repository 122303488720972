import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthModule } from './core/auth/auth.module';
import { CoreModule } from '@core/core.module';
import { HubioLoginModule } from '@hubio/hubio-login';
import { NavigationService } from './app.navigation';
import { HubioCommonPagesModule } from '@hubio/hubio-common-components';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    CoreModule,
    HubioLoginModule.forRoot(environment, new NavigationService()),
    HubioCommonPagesModule.forRoot(environment),
    MDBBootstrapModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate() {
    const isLoggedIn = this.authService.isLoggedIn();
    if (isLoggedIn) {
      this._navigateToModule();
    }
    return !isLoggedIn;
  }

  _navigateToModule() {
    var groups = this.authService.getUserGroups();
    const productOrdered = ['ibc', 'psa', 'rsp', 'admin']
    var route = ""
    productOrdered.forEach(product => {
      if (route == "" && groups.indexOf(`r8-${product}`) != -1) {
        route = `/${product}`;
      }
    });
    this.router.navigate([route]);
  }
}
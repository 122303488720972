import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OktaRedirectComponent } from '@core/auth/components/redirects/okta/okta-redirect.component';
import { ErrorNotFoundComponent } from '@core/pages/error-not-found/error-not-found.component'

import { HubioLoginCallbackComponent, HubioLoginComponent, RouteGuard } from '@hubio/hubio-login';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: HubioLoginComponent
  },
  {
    path: 'login/callback',
    component: HubioLoginCallbackComponent
  },
  {
    path: 'login/redirect/okta',
    component: OktaRedirectComponent
  },
  {
    path: 'ibc',
    loadChildren: './modules/ibc/ibc.module#IbcModule',
    canActivate: [RouteGuard],
    data: {
      allowedGroups: ['r8-ibc', 'r8-module-ibc']
    }
  },
  {
    path: 'psa',
    loadChildren: './modules/psa/psa.module#PsaModule',
    canActivate: [RouteGuard],
    data: {
      allowedGroups: ['r8-psa', 'r8-module-psa']
    }
  },
  {
    path: 'rsp',
    loadChildren: './modules/rsp/rsp.module#RspModule',
    canActivate: [RouteGuard],
    data: {
      allowedGroups: ['r8-rsp', 'r8-module-rsp']
    }
  },
  {
    path: 'fcsa',
    loadChildren: './modules/fcsa/fcsa.module#FcsaModule',
    canActivate: [RouteGuard],
    data: {
      allowedGroups: ['r8-fcsa', 'r8-module-fcsa']
    }
  },
  {
    path: 'admin',
    loadChildren: './modules/admin/admin.module#AdminModule',
    canActivate: [RouteGuard],
    data: {
      allowedGroups: ['r8-admin', 'r8-module-admin']
    }
  },
  {
    path: '**',
    component: ErrorNotFoundComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export abstract class AuthService {
  abstract login(username: string, password: string);
  abstract loginWithOauthToken(token: string);
  abstract logout();
  abstract isLoggedIn()
  abstract refreshToken();
  abstract getJwtToken();
  abstract getUsername();
  abstract getUserGroups();
  abstract isAdmin();
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { Tokens } from '../models/tokens';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../../environments/environment';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthStubService extends AuthService {
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly CURRENT_USER = 'currentUserToken';
  private loggedUser: string;
  private config = {
    apiUrl: `${environment.protocol}://${environment.apiURL}:${environment.authPort}`
  }

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService, private router: Router) {
    super();
  }

  login(username: string, password: string): Observable<boolean> {
    return this.http.post<any>(`${this.config.apiUrl}/login`, { "username": username, "password": password })
      .pipe(
        tap(tokens => this.updateTokens(tokens)),
        mapTo(true));
  }

  logout() {
    return this.http.post<any>(`${this.config.apiUrl}/logout`, {
      'refreshToken': this.getRefreshToken()
    }).pipe(
      tap(() => this.doLogoutUser()),
      mapTo(true),
      catchError(error => {
        alert(error.error);
        return of(false);
      }));
  }

  isLoggedIn() {
    return this.getJwtToken() && !this.isTokenExpired();
  }

  isTokenExpired(token?: string): boolean {
    var token = this.getJwtToken();
    if (!token) return true;
    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    var isExpired = !(date.valueOf() > new Date().valueOf());
    return isExpired;
  }

  getTokenExpirationDate(token: string): Date {
    const decoded: any = jwt_decode(token);
    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  refreshToken() {
    return this.http.post<any>(`${this.config.apiUrl}/refresh`, {
      'refreshToken': this.getRefreshToken()
    }).pipe(tap((tokens: Tokens) => {
      this.updateTokens(tokens);
    }));
  }

  getJwtToken() {
    return localStorage.getItem(this.CURRENT_USER);
  }

  private updateTokens(tokens: Tokens) {
    localStorage.setItem(this.CURRENT_USER, tokens.token);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
  }

  getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  getUsername() {
    const decoded: any = jwt_decode(localStorage.getItem(this.CURRENT_USER));
    return decoded.username;
  }

  isAdmin() {
    const decoded: any = jwt_decode(localStorage.getItem(this.CURRENT_USER));
    return decoded.role === 'admin';
  }

  getUserGroups() {
    const decoded: any = jwt_decode(localStorage.getItem(this.CURRENT_USER));
    return decoded.groups;
  }

  private removeTokens() {
    localStorage.removeItem(this.CURRENT_USER);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }

  loginWithOauthToken(token: string) { }
}
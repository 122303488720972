import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { environment } from '../../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthOktaService extends AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(protected http: HttpClient, protected jwtHelper: JwtHelperService, protected router: Router) {
    super();
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  loginWithOauthToken(token: string) {
    return this.http.post<any>(`${environment.authURL}/oauth2/token`, { 'code': token, 'tenant': environment.tenant })
      .pipe(map(
        user => {
          var userDecoded: any = jwt_decode(user.token);
          user.username = userDecoded.email;
          user.authType = 'okta';
          user.tenant = userDecoded['custom:tenant'] || environment.tenant;
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        })
      );
  }

  login(username: string, password: string) {
    return of(null);
  }

  logout() {
    return this.http.post<any>(`${environment.authURL}/logout`, {}).pipe(map(user => {
      localStorage.removeItem('currentUser'); this.currentUserSubject.next(null);
      return true;
    }));
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  getUser() {
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    delete currentUser['refreshToken'];
    return currentUser;
  }

  getTokenExpirationDate(token: string): Date {
    const decoded: any = jwt_decode(token);
    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (this.currentUserValue != null) {
      token = this.currentUserValue.token;
    }
    if (!token) {
      return true;
    }
    const date = this.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }
    var isExpired = !(date.valueOf() > new Date().valueOf());
    return isExpired;
  }

  refreshToken() {
    var self = this;
    return this.http.post<any>(`${environment.authURL}/refresh-token`, { 'token': this.currentUserValue.refreshToken, 'tenant': 'authentication' })
      .pipe(map(data => {
        self.currentUserValue.token = data.token;
        localStorage.setItem('currentUser', JSON.stringify(self.currentUserValue));
        return data;
      }));
  }

  isAdmin(group?: string): boolean {
    if (this.currentUserValue != null) {
      group = this.currentUserValue.userRole;
    }

    if (!group) {
      return false;
    } else if (group == 'admin') {
      return true;
    }

    return false;
  }

  isLoggedIn() {
    return localStorage.getItem('currentUser') && !this.isTokenExpired();
  }

  getJwtToken() {
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser ? currentUser.token : false;
  }

  getUsername() {
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser.username;
  }

  getUserGroups() {
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const decoded: any = jwt_decode(currentUser.token);
    return decoded['cognito:groups'];
  }
}

import { AuthCognitoService } from '../services/auth-cognito.service';
import { AuthStubService } from '../services/auth-stub.service';
import { AuthOktaService } from '../services/auth-okta.service';

export function AuthenticationFactory(http, jwtHelper, router, authSwitcherService) {
  authSwitcherService.determineAuthService();

  switch (authSwitcherService.authService) {
    case 'okta':
      return new AuthOktaService(http, jwtHelper, router);
    case 'cognito':
      return new AuthCognitoService(http, jwtHelper, router);
    case 'stub':
      return new AuthStubService(http, jwtHelper, router);
    default:
      return new AuthCognitoService(http, jwtHelper, router);
  }
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/auth/services/auth.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-okta-redirect',
  templateUrl: './okta-redirect.component.html',
  styleUrls: ['./okta-redirect.component.scss']
})
export class OktaRedirectComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(
      (queryParams) => {
        if (queryParams.code) {
          this.authService.loginWithOauthToken(queryParams.code).subscribe(
            () => {
              this.router.navigateByUrl('/login');
            },
            (err) => {
              console.error(err);
              this.router.navigateByUrl('/login');
            }
          );
        } else {
          this.router.navigateByUrl('/login');
        }
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '@environment/environment';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  hide: boolean;
  error: boolean;
  oktaRedirectUrl: string | null;

  constructor(
    private authService: AuthService, 
    private formBuilder: FormBuilder, 
    private router: Router) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: [''],
      password: ['']
    });
    this.oktaRedirectUrl = environment.oktaRedirectUrl;
  }

  get f() { return this.loginForm.controls; }

  login() {
    this.authService.login(this.f.username.value, this.f.password.value).subscribe(
      (success) => {
        if (success) {
          this._navigateToModule();
        }
      },
      (error) => {
        this.error = true;
      });
  }

  oktaRedirect() {
    window.location.href = this.oktaRedirectUrl;
  }

  _navigateToModule() {
    var groups = this.authService.getUserGroups();
    const productOrdered = ['ibc', 'psa', 'rsp', 'admin']
    var route = "";
    productOrdered.forEach(product => {
      if (route == "" && groups.indexOf(`r8-${product}`) != -1) {
        route = `/${product}`;
      }
    });

    this.router.navigate([route]);
  }
}

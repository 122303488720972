import { Component, OnInit } from '@angular/core';
import { HubioLoginService } from '@hubio/hubio-login';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  private userGroups = [];

  constructor(
    private authService: HubioLoginService
  ) { }

  ngOnInit() {
    this.userGroups = this.authService.getUserGroups();
  }

  hasGroup(group) {
    // TODO: update to new format of r8-module-whatever
    return (this.userGroups.indexOf(`r8-${group}`) != -1);
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthSwitcherService {
  private _authService: string;

  constructor(private router: Router) { }

  public determineAuthService() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser != null) {
      this._authService = currentUser.authType;
    } else {
      if (this.router.url.indexOf('okta') != -1) {
        this._authService = 'okta';
      } else if (this.router.url.indexOf('cognito') != -1) {
        this._authService = 'cognito';
      } else if (environment.authStub.toLowerCase() === 'true') {
        this._authService = 'stub';
      }
    }
  }

  public get authService(): string {
    return this._authService;
  }
}

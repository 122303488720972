import { INavService } from '@hubio/hubio-login/lib/models/navigation-service';
import jwt_decode from 'jwt-decode';

export class NavigationService implements INavService {
    constructor() { }
  
    /**
     * Dynamically calculate route to be used as homepage
     * @param token jwt access token
     * @returns relative route in app to be used as homepage
     */
    public calculateHomepageRoute(token: string): string {
      const decoded: any = jwt_decode(token);
      const route = (group) => {
        if (decoded['cognito:groups'].includes(group)){
          return decoded['cognito:groups'];
        }
      };

      switch (decoded['cognito:groups']) {
        case route('r8-ibc'):
        case route('r8-module-ibc'):
            return '/ibc/';
        case route('r8-psa'):
        case route('r8-module-psa'):
            return '/psa/';
        case route('r8-rsp'):
        case route('r8-module-rsp'):
            return '/rsp/';
        case route('r8-fcsa'):
        case route('r8-module-fcsa'):
            return '/fcsa/';
        case route('r8-admin'):
        case route('r8-module-admin'):
            return '/admin/';
      }
    }
  }